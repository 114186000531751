.bar5-body {
  cursor: pointer;
  padding: 10px 20px;
  height: 250px;

  .bar5-container {
    height: calc(100% - 10px);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 899px) {
    height: 300px;
    padding: 5px 10px;
    
    .bar5-title {
      font-size: 6px;
    }
    
    .bar5-container {
      height: calc(100% - 15px);
    }
  }
}