$item-height: 30px;
$column-width: 200px;

.scheduler-wrapper {
  .scheduler-box {
    position: relative;

    .css-73xuqe {
      max-width: none !important;

      .css-k7hypl .rs__cell:not(.rs__time) {
        width: 100% !important;
      }

      .MuiTab-root {
        width: $column-width;
        height: 57px;
        padding: 0;
        text-transform: capitalize;
      }
    }

    .loading {
      width: 100%;
      height: calc(100% - 40px);
      position: absolute;
      left: 0;
      top: 40px;
      display: flex;
      align-items: start;
      justify-content: center;
      padding-top: 150px;
      z-index: 9;
    }

    &.loading .css-96e9fn {
      opacity: 0.5;
    }

    .scheduler-timeline {
      position: absolute;
      bottom: 0px;
      font-size: 11px;
      width: 60px;
      background: white;
      border: 1px solid #e4e4e4;
      border-top: none;
      z-index: 99;

      .item {
        height: $item-height;
        line-height: $item-height;
        border-top: 1px solid #e4e4e4;
        letter-spacing: -0.2px;
        white-space: nowrap;
        text-align: right;
        padding: 0 5px;

        &.hour {
          color: black;
          font-size: 12px;
          text-align: center;
          font-weight: 700;
          padding: 0;
          background-image: url('/assets/images/line-pattern.png');
        }
      }
    }
  }

  .schedule-item {
    padding: 5px 10px;
    text-align: left;
    height: 100%;

    p {
      font-size: 0.75rem;
    }
  }

  .css-19s31ak {
    display: none;
  }

  .css-1c41lei-MuiListItem-root {
    background: white;
  }

  &.viewmode-day .css-12aktbw>div,
  &.viewmode-day .css-1mrufi>div {
    max-width: $column-width + 60px;

    &:first-child {
      .css-k7hypl {
        .rs__time {
          position: sticky;
        }
      }

      .css-1c41lei-MuiListItem-root {
        padding-left: 60px;
      }

      .rs__header>div {
        padding-left: 67px;
      }
    }

    &:not(:first-child) {
      max-width: $column-width;

      .css-k7hypl {
        grid-template-columns: 0px repeat(1, 1fr);

        .rs__time {
          visibility: hidden;
        }
      }
    }
  }

  &.viewmode-day .css-12aktbw,
  &.viewmode-day .css-1mrufi>div {
    .css-1dhij82 {
      display: flex;

      .rs__cell {
        width: 100% !important;
        height: 20px !important;
        padding: 0 7px;

        &:first-child {
          display: none;
        }

        &>div> :first-child {
          display: none;
        }
      }
    }
  }

  // Day View Grid
  .css-k7hypl {
    grid-template-columns: 60px repeat(1, 1fr);
    overflow-x: hidden;

    .rs__cell {
      height: $item-height !important;

      &.rs__header> :first-of-type {
        padding: 0;
        font-size: 11px;
        letter-spacing: -0.2px;
        white-space: nowrap;
      }

      &.rs__header.rs__time> :first-of-type {
        writing-mode: horizontal-tb !important;
      }

      &:not(.rs__time) {
        width: $column-width;
      }

      .rs__event__item {
        .MuiPaper-root {
          color: black !important;
          border-radius: 0;
          padding-left: 0px;
          box-shadow: none;
          border: 1px solid black;

          .schedule-item {
            padding-left: 5px;
            height: 100%;
            background: #e4e4e4;
            border-radius: 0;
          }
        }
      }
    }
  }

  .css-1nlly2f {
    overflow-x: hidden;
  }

  // Week View Grid
  .css-z3jy29 {
    overflow-x: hidden;

    .rs__cell {
      height: $item-height !important;

      &.rs__header> :first-of-type {
        padding: 0;
        font-size: 11px;
        letter-spacing: -0.3px;
        white-space: nowrap;
      }

      .rs__event__item {
        .MuiPaper-root {
          color: black !important;
          border-radius: 0;
          box-shadow: none;
          border: 1px solid black;

          .MuiButtonBase-root {
            background: #e4e4e4;

            .schedule-item {
              padding: 3% 4px;

              p {
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }

  // Current time indicator
  .css-19s31ak {
    z-index: 20;
  }
}

.p-1 {
  padding: 3px;
}

.pl-2 {
  padding-left: 8px;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.bold {
  font-weight: bold;
}



.css-1mrufi {
  transform: rotateX(180deg);
  overflow-x: auto;

  >div{
    transform: rotateX(180deg);
  }
}

@media (max-width: 599px) {
  .scheduler-wrapper {

    &.viewmode-day .css-12aktbw>div,
    &.viewmode-day .css-1mrufi>div {
      width: 100%;
    }
  }
}

.autocomplete-datefield,
.autocomplete-servicefield {
  margin-bottom: 17px;

  &.success {
    .MuiInputLabel-root {
      color: #249924;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #249924 !important;
    }

    input {
      color: #0e610e;
    }

    .MuiInputLabel-outlined {
      color: #0e610e;
    }
  }
}
