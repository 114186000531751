.salon-dashboard-wrapper {
  background-color: white;
  margin-top: -20px;
  padding-top: 20px;

  .pl-5p {
    padding-left: 5%;
  }

  .pr-5p {
    padding-right: 5%;
  }

  .responsive-paddings {
    padding-top: 0;
    margin-top: -10px;
  }

  .bg-lightgray {
    background-color: #f2f2f2;
  }

  .border-radius-normal {
    border-radius: 6px;
  }

  .radius-left-reset {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  h5 {
    background-color: white;
    font-size: 1.2vw;
    font-weight: 700;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left !important;
  }

  h5.text-center {
    text-align: center !important;
  }

  .chart-title {
    background-color: white;
    font-size: 1vw;
    font-weight: 600;
    padding-bottom: 5px;
    text-align: center;
  }

  .right-shadow {
    position: relative;
  }

  .right-shadow::after {
    content: "";
    position: absolute;
    top: 0;
    right: -30px;
    width: 30px;
    height: 100%;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: 0;
    background-size: 100% 100%;
    background-image: url(../../assets/images/shadow.png);
    z-index: 1;
  }

  @media (max-width: 900px) {
    .pl-5p {
      padding-left: 2px;
    }

    .pr-5p {
      padding-right: 2px;
    }

    .px-5p {
      padding-left: 15%;
      padding-right: 15%;
    }

    h5 {
      font-size: 3vw;
    }

    .chart-title {
      font-size: 2.5vw;
    }

    .right-shadow::after {
      display: none;
    }
  }

  @media (max-width: 374px) {
    .pl-5p {
      padding-left: 2px;
    }

    .pr-5p {
      padding-right: 2px;
    }

    .px-5p {
      padding-left: 15%;
      padding-right: 15%;
    }

    h5 {
      font-size: 5vw;
    }

    .chart-title {
      font-size: 4vw;
    }

    .right-shadow::after {
      display: none;
    }
  }
}