
.dx-card.wide-card {
    height: 800px; // Set the height for your DataGrid
    // Other styles if any...
  }

  .column-header {
    display: flex;
    align-items: center;
  }
  
  .column-title {
    flex-grow: 1; /* Ensures the title takes the available space */
  }
  
  .sort-icon {
    flex-shrink: 0; 
    width: 24px; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

