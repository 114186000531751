.bar2-body {
  cursor: pointer; 
  padding: 10px;
  height: 300px;

  .bar2-container {
    height: calc(100% - 10px);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 899px) {
    height: 300px;
    padding: 5px;

    .bar2-container {
      height: calc(100% - 15px);
    }
  }
}
