.bar4-body {
  cursor: pointer;
  padding: 10px;
  height: 300px;

  .bar4-container {
    height: calc(100% - 10px);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-tooltip {
    background-color: rgba(0, 0, 0, 0.7);
    color: red;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
  }
  
  @media (max-width: 899px) {
    padding: 5px;
    
    .bar4-container {
      height: calc(100% - 15px);
    }
  }
}
