.async-autocomplete-container {
    width: 100%;
    min-width: 150px;
    position: relative;
    margin-bottom: 17px;

    .error {
        position: absolute;
        top: -15px;
        font-size: 12px;
        color: rgb(221, 85, 85);
        right: 3px;
        font-weight: 500;
    }

    .autocomplete-textfield.success {
        .MuiInputLabel-root {
            color: #249924;
        }

        input {
            color: #0e610e;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #249924 !important;
        }
    }

    .Mui-error {
        color: #9c9c9c !important;
    }

    .Mui-disabled {
        color: #9c9c9c !important;
        font-style: italic;
    }

    &.disabled-box {
        .autocomplete-textfield {
            background-image: url(../../assets/images/line-pattern.png);
            cursor: not-allowed;

            input {
                cursor: not-allowed;
            }
        }

        .button-box {
            .iconbtn {
                cursor: not-allowed;
            }
        }
    }

    .autocomplete-textfield {
        width: 100%;

        input {
            padding-right: 60px;
        }
    }

    .button-box {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 7px;
        right: 5px;
        z-index: 9;

        .iconbtn {
            padding: 5px;

            .icon {
                height: 100%;
                color: #707070;
                font-size: 16px;
            }
        }
    }

    .autocomplete-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        overflow-y: auto;
        z-index: 100;
        background-color: #fff;
        border: 1px solid #bbbbbb;
        border-top: none;
        border-radius: 0 0 5px 5px;

        .list-item {
            cursor: pointer;
            padding: 0 15px;
            overflow-y: auto;

            &:not(:last-child) {
                border-bottom: 1px solid #ebebeb;
            }

            .icon-check {
                color: #1976d2;
                font-size: 16px;
            }

            .item-text {
                span {
                    line-height: 1.5;
                    font-size: 14px;
                }

                p {
                    line-height: 1;
                    font-size: 11px;
                }

                &.no-data {
                    text-align: center;

                    span {
                        font-size: 16px;
                        color: #9c9c9c;
                    }
                }
            }

            &:hover:not(.loading-item) {
                background-color: #1976d215;
            }

            &.Mui-selected {
                background-color: #1976d233;
                border-bottom: none;
                border-left: 3px solid #1976d2;

                * {
                    color: #0355a7;
                }

                span {
                    font-weight: 600 !important;
                }
            }

            &.loading-item {
                padding-top: 10px;
                user-select: none;
                cursor: default;

                .MuiCircularProgress-root {
                    color: #9c9c9c;
                }

                &.Mui-selected {
                    background-color: none;
                }
            }

            .loading-text {
                color: #9c9c9c;
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }
}