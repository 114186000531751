.donuts-body {
  cursor: pointer;
  padding: 20px 10px;
  height: 250px;

  .donuts-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .donuts-percent {
      position: absolute;
      font-size: 1.5vw;
      font-weight: 600;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .donuts-label,
  .donuts-title {
    font-size: 1vw;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  .donuts-label {
    margin-top: 10px;
  }

  @media (max-width: 899px) {
    padding: 2px;
    padding-top: 20px;

    .donuts-container .donuts-percent {
      font-size: 3.5vw;
    }

    .donuts-label,
    .donuts-title {
      font-size: 2vw;
    }

    .donuts-container {
      height: 70%;
    }
  }

  @media (max-width: 599px) {
    .donuts-container .donuts-percent {
      font-size: 4.5vw;
    }

    .donuts-label,
    .donuts-title {
      font-size: 3.5vw;
    }
  }

  @media (max-width: 374px) {
    padding: 2px;
    padding-top: 20px;

    .donuts-container .donuts-percent {
      font-size: 7vw;
    }

    .donuts-label,
    .donuts-title {
      font-size: 5vw;
    }

    .donuts-container {
      height: 70%;
    }
  }
}
