.stylist-dashboard-wrapper {
  background-color: white;
  margin-top: -20px;
  padding-top: 20px;

  .pl-5p {
    padding-left: 5%;
  }

  .pr-5p {
    padding-right: 5%;
  }

  .responsive-paddings {
    padding-top: 0;
    margin-top: -10px;
  }

  .bg-lightgray {
    background-color: #f2f2f2;
  }

  .border-radius-normal {
    border-radius: 6px;
  }

  .radius-left-reset {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  h5 {
    background-color: white;
    font-size: 1.2vw;
    font-weight: 700;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left !important;
  }

  h5.text-center {
    text-align: center !important;
  }

  .chart-title {
    background-color: white;
    font-size: 1vw;
    font-weight: 600;
    padding-bottom: 5px;
    text-align: center;
  }

  .right-shadow {
    position: relative;
  }

  .right-shadow::after {
    content: "";
    position: absolute;
    top: 0;
    right: -30px;
    width: 30px;
    height: 100%;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: 0;
    background-size: 100% 100%;
    background-image: url(../../assets/images/shadow.png);
    z-index: 1;
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  [data-testid="view-navigator"],
  .css-1dhij82 {
    // Today's date
    display: none;
  }

  .css-19s31ak {
    // Current time indicator
    z-index: 20;
  }

  .rs__event__item {
    .MuiPaper-root {
      background: #0dcae7 !important;
      color: black !important;
      border-radius: 0;
      border: 2px solid #cdd9d9;
      padding-left: 15px;
      box-shadow: none;

      .MuiButtonBase-root {
        background: #d9d9d9;

        .schedule-item {
          padding-left: 5px;
          text-align: left;
          
          p {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .scheduler-sider {
    padding: 1rem;
    background-color: #f2f2f2;
    border-radius: 5px;

    .css-1c41lei-MuiListItem-root {
      display: none;
    }
  }

  .css-1sn4lm3-MuiTypography-root {
    margin-top: -12px;
    font-weight: 500;
    font-size: 13px;
  }

  .css-k7hypl {
    border: none;
    padding-top: 15px;
    grid-template-columns: 18% repeat(1, 1fr);

    .rs__cell {
      background: transparent;
    }

    .rs__cell.rs__today_cell {
      border-left: 0;
      border-right: 0;
      border-top: 3px solid #d9d9d9;
    }

    .rs__cell.rs__time {
      align-items: start;
      justify-content: end;
      border: none;
      padding-right: 10px;
      writing-mode: initial !important;
    }
  }

  .summary-container {
    padding: 5px;
  }

  @media (max-width: 900px) {
    h5 {
      font-size: 3vw;
    }

    .chart-title {
      font-size: 2.5vw;
    }
  }

  @media (max-width: 374px) {
    .pl-5p {
      padding-left: 2px;
    }

    .pr-5p {
      padding-right: 2px;
    }

    .px-5p {
      padding-left: 15%;
      padding-right: 15%;
    }

    h5 {
      font-size: 5vw;
    }

    .chart-title {
      font-size: 4vw;
    }
  }
}
