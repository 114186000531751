.bar-body {
  cursor: pointer;
  padding: 10px 30px;
  height: 250px;

  .bar-title {
    font-size: 0.7vw;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 35px;
    margin-top: 0px;
  }

  .bar-container {
    height: calc(100% - 20px);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  @media (max-width: 899px) {
    height: 300px;
    padding: 5px 15px;
    
    .bar-title {
      font-size: 12px;
    }
    
    .bar-container {
      height: calc(100% - 25px);
    }
  }
}
