.stick-container {
  margin-top: 15px;
  padding: 2px;
  cursor: pointer;

  .stick-wrapper {
    background: #d9d9d9;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    color: black;
    min-height: 300px;

    .heading {
      padding: 3px 0;
      text-align: center;
      margin-bottom: 10px;

      .client,
      .date {
        font-weight: 700;
      }
    }

    ul {
      position: relative;
      list-style: none;
      margin: 0;
      padding: 15px 0;

      .resources {
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 5px 0;
        
        .time,
        .cup-name {
          font-weight: 700;
          padding: 0 8px;
          text-align: left;
        }

        .resource {
          padding-left: 8px;
        }

        hr {
          width: 100%;
          max-width: 25%;
          margin: 0;
          margin-top: 9px;
          border-color: black;
          border-width: 1px;
          border-bottom: 0;
          z-index: 1;
        }
      }
    }

    .line {
      position: absolute;
      width: 10%;
      min-width: 20px;
      max-width: 70px;
      height: 90%;
      border: 1px solid #00000070;
      border-radius: 3px;
      top: 10px;
      left: calc(55px + 15%);
      z-index: 0;
    }
  }

  .summary {
    width: 100%;
    background: black;
    color: white;
    padding: 2px 0;
    font-weight: 600;
    font-size: 13px;
    margin-top: 8px;
    border-radius: 5px;
    text-align: center;
  }
}
